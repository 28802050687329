<template lang="">
    <div>
        <img src="@/assets/images/auth/create_account.svg" alt="" class="img-fluid mb-3 d-none d-md-block">
            <h1>Login with you Account</h1>
            <p class="font-italic text-muted mb-0">Create a minimal registeration page using Bootstrap 4 HTML form elements.</p>
            <p class="font-italic text-muted">Snippet By <a href="https://bootstrapious.com" class="text-muted">
                <u>Bootstrapious</u></a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'LeftHero',
}
</script>