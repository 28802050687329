<template>
    <div class="floating-button-wrapper">
        <button class="button-whatsapp" @click="pushTo(nomor)">
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 16 16"><path fill="currentColor" d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144l-2.494.654l.666-2.433l-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931a6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646c-.182-.065-.315-.099-.445.099c-.133.197-.513.646-.627.775c-.114.133-.232.148-.43.05c-.197-.1-.836-.308-1.592-.985c-.59-.525-.985-1.175-1.103-1.372c-.114-.198-.011-.304.088-.403c.087-.088.197-.232.296-.346c.1-.114.133-.198.198-.33c.065-.134.034-.248-.015-.347c-.05-.099-.445-1.076-.612-1.47c-.16-.389-.323-.335-.445-.34c-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654c0 .977.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992c.47.205.84.326 1.129.418c.475.152.904.129 1.246.08c.38-.058 1.171-.48 1.338-.943c.164-.464.164-.86.114-.943c-.049-.084-.182-.133-.38-.232z"/></svg>
            </span>
            <span class="text">WhatsApp</span>
        </button>
    </div>
</template>

<script>
export default {
    props: ['nomor', 'message', 'type'],
    data(){
       return{
         url: "https://wa.me/",
       }
    },
    methods:{
        pushTo(nomor){
            if(this.type === "redirect" ){
                window.open(`${this.url}${nomor}?text=${this.message}`, '_blank');
            }else{
                window.location.href = `${this.url}${nomor}?text=${this.message}`; 
            }
        }
    }
}
</script>

<style scoped>
    .floating-button-wrapper{
        position: fixed;
        z-index: 9999 !important;
        right: 0;
        bottom: 20px;
        padding: 20px 30px;
    }
    .button-whatsapp{
        padding: 18px 20px;
        border-radius: 80px;
        background: #3FE960;
        border: none;
        transition: all 0.1s ease-in;
        font-weight: 500;
        outline: none;
        color: white;
        font-size: 20px;
        justify-content: center;
        display: flex;
        gap: 8px;
        border: 3px solid white;
        box-shadow: 0 0px 20px rgba(0,0,0,0.2);

        font-family: 'Poppins', sans-serif;
    }
    .button-whatsapp:hover{
        padding: 18px 40px;
    }
    .button-whatsapp .text{
        display: none;
        margin-top: 2px;
    }
    .button-whatsapp .icons{
        width: 30px;
        height: 30px;
    }
    .button-whatsapp:hover .text{
        display: block;
    }
    
    @media only screen and (max-width: 780px) {

    .button-whatsapp .icons{
        width: 28px;
        height: 28px;
    }
    .button-whatsapp{
        padding: 14px 16px;
        font-size: 18px;
    }
    .button-whatsapp:hover{
        padding: 14px 40px;
    }
    }
    
</style>