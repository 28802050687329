<template lang="">
    <div>
        <header class="header">
            <nav class="navbar navbar-expand-lg navbar-light py-3">
                <div class="container">
                    <!-- Navbar Brand -->
                    <a href="#" class="navbar-brand">
                        <img src="https://res.cloudinary.com/mhmd/image/upload/v1571398888/Group_1514_tjekh3_zkts1c.svg" alt="logo" width="150">
                    </a>
                </div>
            </nav>
        </header>
    </div>
</template>

<script>
export default {
    name : 'Header',
}
</script>